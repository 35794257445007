<template>
  <transition :name="`slide-${transition}`" appear>
    <div class="w-full p-4">
      <h2 class="font-bold text-xl sm:text-2xl w-full text-left">
        Any additional notes?
      </h2>
      <div class="w-full my-4">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Enter additional notes"
          class="w-full p-2 border rounded"
          v-model="note"
        ></textarea>
      </div>
      <Actions @previous="previousStep" @next="addAdditionalNote">
        <template #previous>
          <span>{{ language.back }}</span>
        </template>
        <template #next>
          <span>{{ language.next }}</span>
        </template>
      </Actions>
    </div>
  </transition>
</template>

<script>
import { ref } from "@vue/reactivity";
import useApp from "../../composables/useApp";
import useBenefitRecord from "../../composables/useBenefitRecord";
import useQuickscreen from "../../composables/useQuickscreen";
import Actions from "../../components/screen/ui/Actions.vue";
import useLanguage from "../../composables/useLanguage";
export default {
  components: {
    Actions,
  },
  setup() {
    const { setLoading } = useApp();
    const { addNote, saveBenefitRecord } = useBenefitRecord();
    const { transition, previousStep } = useQuickscreen();
    const { language } = useLanguage();
    const note = ref("");

    const addAdditionalNote = async () => {
      setLoading(true);

      if (note.value) {
        addNote(note.value, "quickscreen-api@benefitscreening.com");
        await saveBenefitRecord();
      }

      setLoading(false);
      window.location.reload();
    };

    return {
      transition,
      note,
      language,
      previousStep,
      addAdditionalNote,
    };
  },
};
</script>

<style scoped>
textarea::-webkit-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
textarea::-moz-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
textarea::-ms-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
</style>
